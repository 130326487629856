"use client";

import { RootState } from "@/reduxState/store";
import { UIState, setErrorMsg } from "@/reduxState/uiSlice";
import { Input } from "@/components/inputs/Input";
import { Button } from "@/components/ui/Button";
import { ThemedLogo } from "@/components/ui/ThemedLogo";
import { toast } from "@/components/ui/toast";
import { cn } from "@/lib/utils";
import { emailValidation, passwordValidation } from "@/lib/validations/reactHookFormValidations";
import { tryAxiosMultiple } from "@/utils/axios";
import { resIsSuccess } from "@/utils/httpStatus";
import axios from "axios";
import { Loader2 } from "lucide-react";
import { signIn } from "next-auth/react";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Confetti from "react-confetti";
import { FieldValues, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

export default function SignInPage() {
  //const { data: session, status } = useSession();
  const [OTP, setOTP] = useState("");
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSignInWithEmail, setShowSignInWithEmail] = useState(false);
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [get2FAMode, setGet2FAMode] = useState(false);
  const [numSigninAttempts, setNumSigninAttempts] = useState(1);
  const [isSignUpComplete, setIsSignUpComplete] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const searchParams = useSearchParams();
  const router = useRouter();

  const ROOT_DOMAIN_URL = process.env.NEXT_PUBLIC_ROOT_DOMAIN_URL;

  const URLIsSignUpComplete = Boolean(Number(searchParams?.get("signupComplete")));
  const callbackURL = searchParams.get("callbackUrl");

  const twoFACodeLength = 6;

  const signUpEnable = false;

  const UIState = useSelector((state: RootState) => state.ui) as UIState; // Access user state
  const errorMsg = UIState.errorMsg;

  const dispatch = useDispatch(); // Get the dispatch function

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      email: "",
      password: "",
      twoFAToken: "",
    },
  });

  //Handler for when user is having trouble signing in with password and getting spammed with errors
  const onError: SubmitErrorHandler<FieldValues> = (data) => {};

  // Move event listener registration to useEffect
  useEffect(() => {
    const disableScrollIfContentFits = () => {
      const contentHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;

      if (contentHeight <= viewportHeight) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    setIsSignUpComplete(URLIsSignUpComplete);

    // Call the function initially
    disableScrollIfContentFits();

    // Register the event listener
    window.addEventListener("resize", disableScrollIfContentFits);

    // Clean up by unregistering the event listener
    return () => {
      window.removeEventListener("resize", disableScrollIfContentFits);
    };
  }, []);

  useEffect(() => {
    if (numSigninAttempts % 7 === 0 && !resetPasswordMode) {
      toast({
        title: "Hey don't break that, having trouble signing in?",
        message: "Try resetting your password or signing in with an email magic link",
        type: "default",
      });
      setShowSignInWithEmail(true);
    }
  }, [numSigninAttempts]);

  useLayoutEffect(() => {
    window.onload = () => {
      setIsPageLoaded(true);
    };
    //also a timeout to ensure it's loaded
    setTimeout(() => {
      setIsPageLoaded(true);
    }, 300);
  }, []);

  const submitButtonClicked = watch("sign_in_with_password"); // Watch the submitButtonClicked value

  const signInWithEmail: SubmitHandler<FieldValues> = async (data) => {
    setIsLoading(true);

    const email = data.email.toLowerCase();

    const obj = await signIn("email", { email, callbackUrl: callbackURL ? callbackURL : "/entries" });
    setIsLoading(false);
  };

  const signInWithPassword: SubmitHandler<FieldValues> = async (data) => {
    if (loginSuccess) return;
    //console.log("sign in with password", data, OTP);
    setIsLoading(true);

    const email = data.email.toLowerCase();
    const password = data.password;
    const twoFAToken = data.twoFAToken;

    try {
      //check user sign in requirements, is the user banned, verified, or need 2FA?
      /*const res = await axios.post("/api/getUserSignInRequirements", {
        email,
      });*/
      const res = await tryAxiosMultiple("post", "/api/getUserSignInRequirements", { email });

      //console.log("SIGN IN REQ", res);

      if (!res) {
        toast({
          title: "Server Response",
          message: "Could not Authenticate user with server.",
          type: "error",
        });
        throw new Error("Could not Authenticate user with server.");
      }

      if (res.data.invalidCredentials) {
        setPasswordErrorMsg(true);
      }

      //console.log("twoFAToken", twoFAToken);

      if (!twoFAToken) {
        if (res.data.requireTwoFA) {
          setGet2FAMode(true);
          return;
        }
      }

      //console.log("resIsSuccess(res)", resIsSuccess(res));
      if (resIsSuccess(res)) {
        const obj = await signIn("credentials", {
          email,
          password,
          twoFAToken,
          redirect: false,
          callbackUrl: callbackURL ? callbackURL : `${window.location.origin}/entries`,
        });

        //console.log("Sign in obj", obj);

        if (obj && !obj.error && obj.ok && obj.url) {
          window.location.replace(obj.url);
          return;
        } else {
          console.warn("nextAuth failed somehow", obj);
          if ((obj && !obj.ok) || !obj) {
            console.error("nextAuth failed somehow", obj);
          }
          if (!obj || obj.error) {
            setTimeout(() => {
              dispatch(setErrorMsg("Invalid Credentials"));
            }, 100);
          }
        }
      }
    } catch (error: any) {
      console.error(error);
      toast({
        title: "Error",
        message: error.message,
        type: "error",
      });
    } finally {
      setNumSigninAttempts(numSigninAttempts + 1);
      setIsLoading(false);
      setOTP("");
    }
  };

  const toggleResetPasswordMode = async () => {
    setResetPasswordMode(!resetPasswordMode);
  };

  const handleSignUpComplete = () => {
    setIsSignUpComplete(false);
  };

  const sendPasswordResetEmail: SubmitHandler<FieldValues> = async (data) => {
    setIsLoading(true);
    const email = data.email.toLowerCase();

    try {
      const res = await axios.post("/api/resetPassword", {
        email,
      });
      ////console.log("HERE", res.data);
      if (resIsSuccess(res)) {
        if (res.data.message.includes("User is registered but email not verified")) {
          //we need to resend email verification before creating password
          try {
            const response = await axios.post("/api/register", {
              email: email,
            });
            if (resIsSuccess(response.status)) {
              toast({
                title: "Please complete registration",
                message: "Regestration email sent - Check your email",
                type: "default",
              });
            }

            //if on browser, redirect to verify page
            if (typeof window !== "undefined") {
              router.push(`/auth/verify-email?signUp=1&email=${encodeURIComponent(email)}`);
            }
          } catch (err: any) {
            ////console.log("Error with register api", err);
            // Handle the error
            if (err.response.data.message.includes("email already registered")) {
              toast({
                title: "Email already registered",
                message: "Please use a different email or sign in instead.",
                type: "error",
              });
            } else {
              //console.log(err);
              toast({
                title: "Error",
                message: err.response.data.message,
                type: "error",
              });
            }
          } finally {
            setIsLoading(false);
          }
        } else {
          toast({
            title: "Password Reset Email Sent",
            message: "Please check your inbox.",
            type: "success",
          });
        }
        toggleResetPasswordMode();
      } else {
        toast({
          title: "Password Reset Email Failed",
          message: "Please don't check your inbox...",
          type: "error",
        });
      }
    } catch (error: any) {
      //console.log("Error with resetPassword api", error);
      toast({
        title: "Error",
        message: error.response.data.message,
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOTPChange = async (index: any, value: any) => {
    // Ensure the value is a single character
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    // Update the token state
    const newOTP = OTP.split("");
    newOTP[index] = value.toUpperCase();
    const newOTPString = newOTP.join("");
    setOTP(newOTPString);
    //console.log("newOTP", newOTPString, newOTPString.length, twoFACodeLength);

    ////console.log("newOTP", newOTPString, newOTPString.length, twoFACodeLength);

    if (newOTPString.length === twoFACodeLength) {
      setIsLoading(true);
      const valid = await trigger();
      if (valid) signInWithPassword({ ...watch(), twoFAToken: newOTPString });
    }

    // Move focus to the next input field
    if (value && inputsRef.current[index + 1]) {
      const nextInput = inputsRef.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      }
    }

    return;
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    // Move focus to the previous input field if the user pressed backspace
    if (value === "" && event.key === "Backspace" && inputsRef.current[index - 1]) {
      const prevInput = inputsRef.current[index - 1];
      if (prevInput) {
        prevInput.focus();
      }
    } else if (event.key === "Enter") {
      const nextInput = inputsRef.current[index + 1];
      if (nextInput) {
        nextInput.focus();
      } else {
        handleOTPChange(index, value);
      }
    }
  };

  const getMaxInputIndex = () => {
    if (!inputsRef.current || inputsRef.current.length === 0) return 0;

    const highestIndex = inputsRef.current.reduce((highestIndex, currentInput) => {
      if (!currentInput) {
        return highestIndex;
      }
      const currentIndex = parseInt(currentInput.id.split("-")[1]);

      if (isNaN(currentIndex)) {
        return highestIndex;
      }

      return currentIndex > highestIndex ? currentIndex : highestIndex;
    }, 0);

    return highestIndex + 1;
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const pastedData = event.clipboardData.getData("text");
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    const { selectionStart, selectionEnd } = input;
    const expectedTokenLength = getMaxInputIndex();
    //const pastedDataLength = pastedData.length;

    if (selectionStart !== null && selectionEnd !== null) {
      const start = currentValue.substring(0, selectionStart);
      const end = currentValue.substring(selectionEnd);
      const newValue = start + pastedData + end;

      const trimmedValue = newValue.slice(0, expectedTokenLength);

      // Update the token state
      setOTP(trimmedValue);

      // Move focus to the appropriate input field
      const pasteIndex = index + selectionStart;
      if (inputsRef.current[pasteIndex]) {
        const nextInput = inputsRef.current[pasteIndex];
        nextInput.focus();

        // Set the value of subsequent input boxes based on the pasted value
        const remainingValues = newValue.split("").slice(pasteIndex + pastedData.length);
        remainingValues.forEach((val, idx) => {
          const input = inputsRef.current[pasteIndex + 1 + idx];
          if (input) {
            input.value = val || "";
          }
        });
      }

      // Prevent the default paste behavior
      event.preventDefault();
    }
  };

  return (
    <div className="flex h-screen w-full justify-center bg-background-blue sm:px-6 lg:px-8">
      {isSignUpComplete && (
        <>
          <div className="pointer-events-none fixed inset-0 bg-primary opacity-25"></div>
          <div className="z- pointer-events-none fixed inset-0 flex items-center justify-center">
            <div className="rounded-lg bg-white bg-opacity-100 p-6 opacity-100 shadow-lg">
              <h2 className="mb-4 text-2xl font-bold">Sign Up Complete!</h2>
              <button className="rounded bg-blue-500 px-4 py-2 text-white" onClick={handleSignUpComplete}>
                Continue to Sign In
              </button>
            </div>
          </div>

          <Confetti width={window.innerWidth} height={window.innerHeight} recycle={false} />
        </>
      )}
      <div
        id="Center-Me"
        className={cn(
          "transition-opacity duration-0",
          { "opacity-100": isPageLoaded, "opacity-0": !isPageLoaded },
          "flex min-w-full flex-col items-center justify-center px-1 md:justify-normal md:pt-[240px] [@media(min-width:320px)]:px-6 "
        )}
      >
        <div className="flex items-center justify-center">
          <ThemedLogo
            force={"dark"}
            width={250}
            height={250}
            className="flex h-auto max-w-[250px] p-1"
            sizes="(max-width: 200px) 100vw,
              (max-width: 200px) 50vw,
              33vw"
          />
        </div>
        {!isSignUpComplete && (
          //normal page usage
          <div className="mt-8 w-full max-w-[500px] rounded-md bg-background-light text-black dark:text-white md:mx-0">
            <div className="mt-4 p-8 sm:rounded-lg sm:px-10">
              {get2FAMode ? (
                <>
                  <div className="flex justify-between pb-2 sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className=" text-xl font-bold text-primary">Two-factor authentication required</h2>
                  </div>
                  <div className="mx-auto flex w-full flex-col justify-between rounded-md bg-background-secondary px-1 pb-4 pt-10 font-normal text-tertiary-light sm:px-0 ">
                    <h4>Authentication code</h4>
                    {!isLoading ? (
                      <div
                        className="my-2 flex justify-between gap-x-6"
                        style={{
                          transition: `opacity 1000ms`,
                          transitionDelay: `250ms`,
                          opacity: get2FAMode ? 1 : 0,
                        }}
                      >
                        {Array.from({ length: twoFACodeLength }, (_, index) => (
                          // Render the inputs only when isLoading is false
                          <Input
                            id={`OTP-${index}`}
                            label=""
                            variant="outline"
                            size="full"
                            key={index}
                            otherType="text"
                            maxLength={1}
                            disabled={isLoading}
                            style={{
                              transition: `opacity 1000ms`,
                              transitionDelay: `${index * 250}ms`,
                              transitionDuration: `2500ms`,
                              opacity: get2FAMode ? 1 : 0,
                            }}
                            className="h-[60px] overflow-visible text-center text-3xl font-extrabold"
                            classNameInputBox="text-2xl focus:outline focus:outline-2 focus:outline-skyBlue h-[60px]"
                            onChange={(e) => {
                              handleOTPChange(index, e.target.value);
                            }}
                            onPaste={(e) => handlePaste(e, index)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={(ref) => {
                              inputsRef.current[index] = ref;
                            }}
                            onClick={() => {
                              const input = inputsRef.current[index];
                              if (!input) return;
                              const length = input.value.length;
                              input.setSelectionRange(length, length);
                              input.focus(); // This ensures the input is focused if it wasn't already
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      //show loading
                      <div className="flex justify-center">
                        <Loader2 className="animate-spin" size={24} />
                      </div>
                    )}
                    <p className="py-2">Open your two-factor authentication app or check your email for the authentication code and enter it above.</p>
                  </div>
                  <div className="mb-3">
                    {/*<Button
											id="sign_in_with_password"
											name="sign_in_with_password"
											type="submit"
											variant="purple"
											size="full"
											onClick={handleSubmit(signInWithPassword, onError)}
										>
											{isLoading ? (
												<Loader2 className="animate-spin" size={24} />
											) : (
												<>
													<span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
													Verify
												</>
											)}
										</Button>
											*/}
                  </div>
                </>
              ) : (
                <>
                  <div className="flex justify-between pb-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="whitespace-nowrap text-left text-xl font-bold text-primary">
                      {!resetPasswordMode ? "Sign In" : "Reset your password"}
                    </h2>
                    {!resetPasswordMode && (
                      <div className="text-right">
                        {signUpEnable ? (
                          <a href="/try" className="font-medium text-skyBlue underline hover:text-darkPurple">
                            Or create account.
                          </a>
                        ) : (
                          <a href="https://refdocs.com/beta/" className="font-medium text-skyBlue hover:text-darkPurple hover:underline">
                            I don&apos;t have an account
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="my-4">
                    <Input
                      id="email"
                      label="Email"
                      otherType="text"
                      disabled={isLoading}
                      register={register}
                      //onChange={handleLastNameChange}
                      //value={lastName}
                      validationSchema={emailValidation}
                      required={true}
                      errors={errors}
                      data-testid="email"
                    />
                  </div>
                  {!resetPasswordMode && (
                    <>
                      <div className="my-4">
                        <Input
                          id="password"
                          label="Password"
                          data-testid="password"
                          otherType="password"
                          disabled={isLoading}
                          register={register}
                          onChange={(e) => {
                            if (errorMsg) dispatch(setErrorMsg(""));
                          }}
                          onKeyDown={(e) => {
                            //console.log("e", e);
                            if (e.key === "Enter") {
                              handleSubmit(signInWithPassword, onError)?.();
                            }
                          }}
                          required={true}
                          errors={errors}
                          error={errorMsg}
                        />
                      </div>
                    </>
                  )}
                  {!resetPasswordMode ? (
                    <>
                      <div className="my-3">
                        <Button
                          id="sign_in_with_password"
                          name="sign_in_with_password"
                          type="submit"
                          variant="purple"
                          size="full"
                          onClick={handleSubmit(signInWithPassword, onError)}
                          isLoading={isLoading}
                        >
                          <>
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                            {showSignInWithEmail ? "Sign In With Password" : "Sign In"}
                          </>
                        </Button>
                      </div>
                      {showSignInWithEmail && (
                        <div className="my-3">
                          <Button type="submit" size="full" variant="purple" onClick={handleSubmit(signInWithEmail)}>
                            {isLoading ? (
                              <Loader2 className="animate-spin" size={24} />
                            ) : (
                              <>
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                                Sign In With Magic Email Link
                              </>
                            )}
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="py-3">
                      <Button type="submit" variant="purple" size="full" onClick={handleSubmit(sendPasswordResetEmail)}>
                        {isLoading ? (
                          <Loader2 className="animate-spin" size={24} />
                        ) : (
                          <>
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                            Send Password Reset Email
                          </>
                        )}
                      </Button>
                    </div>
                  )}
                  <div className="mb-0 pb-0 pl-1 text-center text-xs">
                    <Button variant="ghost" size="fit" className="text-xs" onClick={toggleResetPasswordMode}>
                      {resetPasswordMode ? "Remembered your password?" : "Forgot your password?"}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
